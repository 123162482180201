import { Component } from '@angular/core';
import { trigger, state, animate, query, transition, style, stagger } from
  '@angular/animations';

export const fadeAnimation = trigger('fade', [
  state('inactive', style({ opacity: 0 })),
  state('active', style({ opacity: 1 })),
  transition('* <=> *', [
    animate(2000)
  ])
]);

export const upDownAnimation = trigger('upDown', [
  state('inactive', style({ 'animation': 'none' })),
  state('active', style({ 'animation': 'up_down 15s infinite' })),
  transition('* <=> *', [
    animate(2000)
  ])
]);
